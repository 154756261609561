import { defineStore } from "pinia";
import { useRuntimeConfig } from "#app";
import { useTeamStore } from "./team";

export const useAssistantStore = defineStore("assistant", {
  state: () => ({
    message: "",
    history: [],
    notice: false, // show welcome notice
    enabled: false, // enable assistant
    active: false, // show assistant
    options: {
      mode: "",
      choices: [
        { name: "Write some content", value: "write" },
        { name: "Suggest some ideas", value: "suggest" },
        { name: "Do some research", value: "research" },
        { name: "Make a comparison", value: "compare" },
      ],
    },
  }),

  actions: {
    dismissNotice() {
      this.notice = false;
    },
    async getMessages(payload) {
      const teamStore = useTeamStore();
      const fetch = await $fetch(
        `${useRuntimeConfig().public.api}/assistant/messages/get`,
        {
          query: {
            tenant: teamStore.$state.activeTeam.tenantId,
          },
        }
      );
      if (fetch) {
        this.history = fetch;
      }
    },
    async clearMessages(payload) {
      const teamStore = useTeamStore();
      const { token } = useAuth();
      const post = await $fetch(
        `${useRuntimeConfig().public.api}/assistant/messages/clear`,
        {
          method: "POST",
          body: { id: teamStore.$state.activeTeam._id },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (post) {
        this.history = [];
      }
    },
    async sendMessage(payload) {
      const teamStore = useTeamStore();
      const { token } = useAuth();
      const post = await $fetch(
        `${useRuntimeConfig().public.api}/assistant/messages/send`,
        {
          method: "POST",
          body: {
            tenant: teamStore.$state.activeTeam.tenantId,
            message: payload.message,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (post) {
        this.message = "";
      }
    },
    toggleAsistant() {
      this.active = !this.active;
    },
    setChoice(payload) {
      this.options.mode = payload.mode;
    },
  },
});
