<template lang="pug">
.assistant-console
  .content
    .has-text-centered(v-if="$store.state.assistant.history.length === 0")
      .assistant-console__robot
          img(src="~assets/images/fitz.svg")
      .title.has-text-white Beep boop, I'm Fitz, your intelligent assistant!
      .subtitle.has-text-grey-light Select a task for me below and let's get started.
    .assistant-console__dialog(v-if="$store.state.assistant.history.length > 0")
      .assistant-console__chat(v-for="(chat, index) in $store.state.assistant.history" :key="index" v-bind:class="chat.type === 'bot' ? 'bot' : 'user'")
        .assistant-console__bubble
          | {{ chat.message }}
        .assistant-console__author(v-if="chat.type === 'user'")
          Avatar
          | You - {{ chat.date | date-time }}
        .assistant-console__author(v-if="chat.type === 'bot'")
          | Fitz - {{ chat.date | date-time }}
          img(src="~assets/images/fitz-white.svg")
      .assistant-console__clear
        o-button(v-on:click="clearMessages()" icon-left="delete" type="is-danger" outlined) Clear message history
    .assistant-console__window
      div(v-if="$store.state.assistant.options.mode === ''")
        div.title.has-text-centered.has-text-light.is-5 Select a Task to Begin
        div.m-2(v-for="choice in $store.state.assistant.options.choices" v-on:click="setChoice(choice.value)")
          o-button(icon-left="robot" type="is-success" expanded) {{ choice.name }}
      div(v-else)
        o-button.mb-4(v-on:click="setChoice('')" icon-left="arrow-left" type="is-white" outlined expanded) Return to Task Selection
        o-field(custom-class="has-text-light" label="Type your query" v-if="$store.state.assistant.options.mode === 'write'")
          o-input(maxlength="200" type="textarea" v-model="message" v-on:keyup.native.enter="submitMessage()")
</template>
<script>
export default {
  data() {
    return {
      message: "",
    };
  },
  computed: {
    hashedUser() {
      return md5(this.$auth.user.data.username);
    },
    status() {
      return this.$store.state.assistant.active;
    },
  },
  watch: {
    status(newStatus, oldStatus) {
      if (newStatus === true) {
        this.scrollToBottom();
      }
      // console.log(`We have ${newStatus} status now, yay!`)
    },
  },
  mounted() {
    this.getMessages();
  },
  methods: {
    getMessages() {
      this.$store.dispatch("assistant/GET_MESSAGES");
    },
    submitMessage() {
      if (this.message) {
        const loadingComponent = this.$buefy.loading.open({
          container: null,
        });
        this.$store
          .dispatch("assistant/SEND_MESSAGE", {
            message: this.message,
          })
          .then(() => {
            this.message = "";
            this.$store.dispatch("assistant/GET_MESSAGES").then(() => {
              setTimeout(() => {
                loadingComponent.close();
                this.scrollToBottom();
              }, 100);
            });
          });
      }
    },
    setChoice(mode) {
      this.$store.dispatch("assistant/SET_CHOICE", {
        mode,
      });
    },
    scrollToBottom() {
      const assistant = document.querySelector(".assistant-console");
      assistant.scrollTop = assistant.scrollHeight;
      // document.querySelector('.assistant-console').scrollTop = 0
      // document.querySelector('.assistant-console').scrollTo(0, 0)
      // document
      //   .querySelector('.assistant-console')
      //   .scrollTo(document.querySelector('.assistant-console').scrollHeight, 0)
    },
    clearMessages() {
      this.$buefy.dialog.confirm({
        message: `Permanently Remove Chat Messages`,
        onConfirm: () => {
          this.$store.dispatch("assistant/CLEAR_MESSAGES");
        },
      });
    },
  },
};
</script>
<style lang="scss">
.assistant-console {
  width: rempx(800);
  // background: #111;
  background: #222;
  height: 100%;
  color-scheme: dark;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding-bottom: rempx(400);
  position: relative;
  &__window {
    position: fixed;
    width: rempx(783);
    padding: rempx(20);
    z-index: 9;
    bottom: 0;
    left: 0;
    background: #333;
    min-height: rempx(300);
  }
  &__robot {
    width: rempx(180);
    margin: 0 auto;
    background: #fff;
    padding: rempx(20);
    border-radius: 50%;
    margin-top: rempx(80);
    margin-bottom: rempx(20);
  }
  &__chat {
    width: 100%;
    box-sizing: border-box;
    padding: rempx(40);
    padding-bottom: 0;
    color: #ccc;
    // margin: rempx(60);
  }

  &__clear {
    padding: rempx(20) rempx(40) 0;
    text-align: center;
  }

  // &__bubble {
  //   position: relative;
  //   background: $primary;
  //   color: #fff;
  //   padding: rempx(40);
  //   &:before {
  //     content: '';
  //     position: absolute;
  //     bottom: rempx(-20);
  //     width: 0;
  //     height: 0;
  //     border-style: solid;
  //     border-width: 0 rempx(20) rempx(20) 0;
  //     border-color: transparent $primary transparent transparent;
  //   }
  // }
  // &__author {
  //   font-size: rempx(20);
  //   margin-top: rempx(20);
  //   margin-left: rempx(60);
  // }

  .user {
    .assistant-console__bubble {
      position: relative;
      background: $primary;
      color: #fff;
      padding: rempx(40);
      margin-right: rempx(40);
      &:before {
        content: "";
        position: absolute;
        bottom: rempx(-20);
        left: rempx(20);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 rempx(20) rempx(20) 0;
        border-color: transparent $primary transparent transparent;
      }
    }
    .assistant-console__author {
      font-size: rempx(14);
      margin-top: rempx(20);
      margin-left: rempx(60);
      display: flex;
      align-items: center;
      gap: rempx(20);
      height: rempx(67);
    }
  }

  .bot {
    .assistant-console__bubble {
      position: relative;
      // background: #111;
      // color: #fff;
      background: #fff;
      color: #111;
      padding: rempx(40);
      margin-left: rempx(40);
      &:before {
        content: "";
        position: absolute;
        left: auto;
        bottom: rempx(-20);
        right: rempx(20);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: rempx(20) rempx(20) 0 0;
        border-color: #fff transparent transparent transparent;
      }
    }
    .assistant-console__author {
      font-size: rempx(14);
      margin-top: rempx(20);
      margin-right: rempx(60);
      display: flex;
      align-items: center;
      gap: rempx(20);
      justify-content: flex-end;
      text-align: right;
      img {
        max-width: rempx(64);
        // background: #fff;
        padding: rempx(4);
        border-radius: 250%;
      }
    }
  }
}
</style>
