<template lang="pug">
.assistant-icon
  .assistant-icon__close
  .assistant-icon__inner
    //- img(src='~assets/images/fitz-white.svg')
    .assistant-icon__icon(v-on:click="openAiAssistant()")
      o-icon.mb-2(icon='robot' size='is-large' type='is-white')
      //- .has-text-white.mt-1 Fitz {{ aiAssistantOpen }}
    .assistant-icon__intro(v-if="$store.state.assistant.notice")
      .assistant-icon__welcome
        //- img(src='~assets/images/fitz.svg')
        .content
          p.title.is-7.has-text-success.mb-2.is-uppercase Announcement
          p.title.is-5.has-text-white.mb-2 Meet Fitz, your AI assistant!
          p.has-text-grey-lighter Harness the power of AI to generate content, suggest topics, assist in research, manage your brand, and much more.
          .buttons(v-on:click="dismissNotice()")
            o-button(v-on:click="openAiAssistant()" icon-left="navigation-outline" type="is-success" expanded) Explore
            o-button(icon-left="close" type="is-light" outlined expanded) Dismiss
</template>
<script>
export default {
  methods: {
    openAiAssistant() {
      this.$store.dispatch("assistant/TOGGLE_ASSISTANT");
    },
    dismissNotice() {
      this.$store.dispatch("assistant/DISMISS_NOTICE");
    },
  },
};
</script>
<style lang="scss">
@keyframes border-pulse {
  0% {
    border-color: #ccc;
  }
  50% {
    // border-color: $primary;
    border-color: #48c78e;
  }
  100% {
    border-color: #ccc;
  }
}

.assistant-icon {
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  &__inner {
    position: relative;
    width: 80%;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // background: $primary;
    background: #111;
    border-radius: 50%;
    border: 2px solid #ccc;
    animation-name: border-pulse;
    animation-duration: 4s;
    // animation-iteration-count: infinite;
    animation-iteration-count: 5;
    animation-timing-function: ease-in-out;
    img {
      max-width: rempx(60);
    }
  }
  &__icon {
    i {
      transition: 200ms all;
      &:hover {
        color: #48c78e;
        // color: $primary;
      }
    }
  }
  &__intro {
    cursor: auto;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(80px);
    // background: $primary;
    background: #111;
    img {
      width: 100%;
      max-width: rempx(80);
    }
  }
  &__welcome {
    padding: rempx(20);
    color: #fff;
    width: rempx(280);
  }
}
</style>
